import React from "react"

import SimpleLayout from "../layouts/SimpleLayout"
import SEO from "../components/seo"
import POSSubscriptionForm from "../components/Forms/POSSubscriptionForm"

import styles from "./styles/subscription.module.css"

const SubscriptionPageComponent = ({
  seoTitle,
  seoDescription,
  seoImage,
  title,
  subtitle,
  notes,
  isPreview,
  ...props
}) => (
  <SimpleLayout path={props.path} isPreview={isPreview}>
    <SEO
      title={seoTitle}
      description={seoDescription}
      image={seoImage}
      url="https://cloudware.pt/pos-subscrever"
    />
    <section className={styles.subscriptionHeader} />

    <section className={styles.subscriptionForm}>
      <div className={styles.content}>
        <POSSubscriptionForm
          {...props}
          title={title}
          subtitle={subtitle}
          notes={notes}
        />
      </div>
    </section>
  </SimpleLayout>
)

export default SubscriptionPageComponent
