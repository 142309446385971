import React from "react"

const PlanList = React.forwardRef(({ styles, name, error, style }, ref) => (
  <div
    className={` ${error ? styles.inputError : ""} ${styles.selectContainer}`}
  >
    <img
      className={styles.dropdownIcon}
      src={require("../../../img/dropdown.png")}
      alt=""
    />
    <select
      style={style}
      name={name}
      ref={ref}
      className={error ? styles.inputError : ""}
      placeholder="Tipo de Subscrição"
    >
      <option value="" disabled>
        Tipo de Subscrição
      </option>

      <option value="pos">POS</option>
      <option value="pos_cloudware_business">
        POS com integração com o Cloudware Business
      </option>
      <option value="pos_toconline">POS com integração com o TOConline</option>
    </select>
  </div>
))

export default PlanList
