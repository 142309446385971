import React from "react"
import { StaticQuery, graphql } from "gatsby"

import SubscriptionPageComponent from "../page-components/SubscriptionPageComponent"

const imageSrcFormatter = (imageSrc, type = "fixed") =>
  (imageSrc &&
    imageSrc.childImageSharp &&
    imageSrc.childImageSharp[type] &&
    imageSrc.childImageSharp[type].src) ||
  ""

const query = graphql`
  query {
    page: markdownRemark(fields: { slug: { eq: "/pages/pos-subscription/" } }) {
      frontmatter {
        seoTitle
        seoDescription
        seoImage {
          childImageSharp {
            fixed(width: 480, quality: 92) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        title
        subtitle
        notes
      }
    }
  }
`
const POSSubscriptonPage = props => (
  <StaticQuery
    query={query}
    render={data => {
      const {
        title,
        subtitle,
        notes,
        seoTitle,
        seoDescription,
        seoImage,
      } = data.page.frontmatter

      return (
        <SubscriptionPageComponent
          title={title}
          subtitle={subtitle}
          notes={notes}
          seoTitle={seoTitle}
          seoDescription={seoDescription}
          seoImage={imageSrcFormatter(seoImage)}
          {...props}
        />
      )
    }}
  />
)

export default POSSubscriptonPage
