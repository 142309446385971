import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"

import { submitPOSSubscriptionForm } from "../../forms"
import DistrictList from "./components/DistricList"
import TermsAndConditions from "./components/TermsAndConditions"
import PlanList from "./components/PlanList"

import styles from "./styles/subscription_form.module.css"

const POSSubscriptionForm = ({ title, subtitle, notes, ...props }) => {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const { register, handleSubmit, errors, reset, watch } = useForm({
    defaultValues: {
      district: "",
      subscription_plan: "",
      subscription_peroid: props.location
        ? props.location.search.replace("?plan=", "")
        : "",
    },
  })

  const districtValue = watch("district", "")
  const planValue = watch("subscription_plan", "")

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false)
        reset()
      }, 10000)
    }
    /* eslint-disable-next-line */
  }, [success])

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null)
        reset()
      }, 10000)
    }
    /* eslint-disable-next-line */
  }, [error])

  const onSubmit = async data => {
    setLoading(true)

    try {
      await submitPOSSubscriptionForm(data)
      setSuccess(true)
    } catch (err) {
      setError(err)
    }
    setLoading(false)
  }

  const SuccessMessage = () => (
    <div
      className={styles.success}
      style={success ? { opacity: 1 } : { opacity: 0, zIndex: -1 }}
    >
      <p> O seu pedido de subscrição foi submetido com sucesso! </p>
      <p>
        Receberá, de seguida, um e-mail com as instruções para acesso à empresa
        de demonstração.
      </p>
      <p>Obrigado e até já!</p>
    </div>
  )

  const ErrorMessage = () => (
    <p className={styles.error}>
      Ocorreu um erro no envio da sua mensagem. Por favor, tente novamente mais
      tarde.
    </p>
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <h2>{title}</h2>
      <p>{subtitle}</p>

      <div className={styles.inline}>
        <input
          className={`${errors.first_name ? styles.inputError : ""} ${
            styles.half
          }`}
          name="first_name"
          placeholder="Primeiro Nome"
          ref={register({
            required: "Por favor insira o seu primeiro nome",
          })}
        />
        <input
          className={`${errors.last_name ? styles.inputError : ""} ${
            styles.half
          }`}
          name="last_name"
          placeholder="Último Nome"
          ref={register({
            required: "Por favor insira o seu último nome",
          })}
        />
      </div>

      <input
        className={errors.email ? styles.inputError : ""}
        name="email"
        placeholder="Email"
        ref={register({
          required: "Por favor insira o seu email",
        })}
      />
      <input
        className={errors.phone ? styles.inputError : ""}
        name="phone"
        placeholder="Telemóvel"
        ref={register()}
      />
      <DistrictList
        style={{
          color: districtValue ? "#3f3f3f" : "#8e8e8e",
        }}
        styles={styles}
        name="district"
        ref={register({
          required: "Por favor insira o seu distrito",
        })}
        error={errors.district}
      />
      <input
        className={errors.company_name ? styles.inputError : ""}
        name="company_name"
        placeholder="Nome da Empresa"
        ref={register({
          required: "Por favor insira o nome da sua empresa",
        })}
      />
      <input
        className={errors.nif ? styles.inputError : ""}
        name="nif"
        placeholder="NIF"
        ref={register({
          required: "Por favor insira o NIF da sua empresa",
          maxLength: { value: 9, message: "Por favor insira um NIF válido" },
          minLength: { value: 9, message: "Por favor insira um NIF válido" },
        })}
      />
      <input
        className={errors.promo_code ? styles.inputError : ""}
        name="promo_code"
        placeholder="Código promocional"
        ref={register()}
      />
      <PlanList
        style={{
          color: planValue ? "#3f3f3f" : "#8e8e8e",
        }}
        styles={styles}
        name="subscription_plan"
        ref={register({
          required: "Por favor indique o plano desejado",
        })}
        error={errors.subscription_plan}
      />

      {/* <ReactMarkdown source={notesOk} className={styles.notes} /> */}
      <div className={styles.checkbox}>
        <input
          type="checkbox"
          name="accepted_terms"
          ref={register({
            required: "Por favor aceite os termos e condições",
          })}
        />
        <TermsAndConditions />
      </div>

      <button type="submit" className={styles.button}>
        {loading ? "A enviar..." : "Experimentar"}
      </button>
      {Object.keys(errors).map(error => (
        <p className={styles.error} key={error}>
          {errors[error].message}
        </p>
      ))}
      {success && <SuccessMessage />}
      {error && <ErrorMessage />}
    </form>
  )
}

export default POSSubscriptionForm
